import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { LocalStorageService } from 'projects/urp/src/app/services/storage.service/local-storage.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-sidebar-lang',
  templateUrl: './sidebar-lang.component.html'
})
export class SidebarLangComponent implements OnInit {
  isLoggedIn: boolean = false;
  languages: any;
  selectedLanguage: any;
  selectedLanguageLabel: string;
  selectedLanguageId: string;
  isLoading = true;
  constructor(private httpService: HttpService,
              private storageService: LocalStorageService,
              private translateService: TranslateService,
              private toastr: ToastrServiceExt,
              private helper: HelperService,
              private keycloak: KeycloakService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) {
  }

  public async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    this.loadLanguages();
  }

  loadLanguages(): void {
    let url: any = `${this.environment.api.language}/search`;
    if (!this.isLoggedIn)
      url = `${this.environment.api.pubLanguage}/search`;
    this.httpService.getList(url, { IsDeleted: 'false' }).subscribe(f => {
      this.languages = f;
      const l = f.map(x=> {return x.code})
      this.storageService.store(this.storageService.KEY_LANGUAGE_LIST, l);
      this.selectedLanguage = this.helper.getCurrentSystemLanguage();
      this.selectedLanguageLabel = this.languages.find(x => x.code === this.helper.getCurrentSystemLanguage()).label;
      this.selectedLanguageId = this.languages.find(x => x.code === this.helper.getCurrentSystemLanguage()).value;
      this.storageService.store(this.storageService.KEY_SYSTEM_LANGUAGE, this.selectedLanguage);
      this.storageService.store(this.storageService.KEY_LANGUAGE_ID, this.selectedLanguageId);
      this.isLoading = false;
    }, (err) => {
      this.toastr.serverError(err);
    });
  }

  onLanguageChange(selectedLanguage: string): void {
    this.selectedLanguage = selectedLanguage;
    this.selectedLanguageLabel = this.languages.find(x => x.code === selectedLanguage).label;
    this.selectedLanguageId = this.languages.find(x => x.code === selectedLanguage).value;
    this.storageService.store(this.storageService.KEY_SYSTEM_LANGUAGE, selectedLanguage);
    this.storageService.store(this.storageService.KEY_LANGUAGE_ID, this.selectedLanguageId);
    
    this.translateService.use(selectedLanguage);
  }

  getLanguageCode(code): string {
    //TODO: fix language mess
    if (code === 'EN') {
      return 'GB';
    }
    
    return code;
  }

}
