
<div *ngIf="loading" class="container-fluid">
  <app-loader type="list" ></app-loader>
</div>

<div *ngIf="!loading">
  <app-breadcrumb [isHeader]="true"></app-breadcrumb>
  <div class="bg-secondary px-3">
    <div [ngClass]="{'filter-closed': isFilterClosed, 'pt-3': !isFilterClosed}">
      <div class="d-flex">
        <h3 class="me-auto" translate>pages.translation.filter.title</h3>
        
        <div class="form-group">
          <a translate class="cp-text-primary cp-font-weight-600"
            (click)="clearFilter()">pages.common.list.clearFilterButton</a>
            <a translate class="btn btn-outline-primary close-filter ms-3"
            (click)="closeFilter()">pages.common.list.closeFilterButton
            <i class="fa-solid fa-xmark"></i>
            </a>
        </div>
      </div>
    </div>
    <div [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <div class="justify-content-center">
        <formio class="formio" #formio [form]="form" [language]="language" [submission]="formSubmission"
        [options]="formOptions" (customEvent)="formioCustomEvent($event)" (change)="formioChange($event)"
        [refresh]="refreshForm" (render)="render()">
        </formio>
      </div>
    </div>
    <div class="form-group mt-3" [ngClass]="{'filter-closed': isFilterClosed, 'd-none': isFilterClosed}">
      <a translate class="btn btn-primary cp-font-weight-700"
        (click)="onFilter()">pages.common.list.searchButton
      </a>
    </div>
   </div>

  <input #fileInput type="file" (change)="onFileSelected($event)" accept=".csv" style="display: none;">

<ng-container>
  <div class="table-holder">
    <div class="grid-classifier-header">
      <div>    
        <h3 class="me-auto" translate>pages.translation.list.title</h3>
      </div>
      <div *ngIf="isFilterClosed">
          <a class="btn btn-outline-primary open-filter-margin btn-right float-end" (click)="openFilter()" translate>pages.common.list.filterButton
              <i class="fa-solid fa-list"></i>
          </a>
      </div>
  </div>
  <app-base-filter [disableImportExport]=false
  [disableAddNew]="disableAddNew"
  [btnAddNewTitle]="'pages.translation.list.addNewButton'"
  (clickCreate)="onCreate()" 
  [btnImportTitle]="'pages.translation.list.import'"
  (clickImport)="onImport()"
  [btnExportTitle]="'pages.translation.list.export'"
  (clickExport)="onExport()"
  (clickBack)="onBack()">
</app-base-filter>
    <app-data-table *ngIf="!classifiersLoading" [tableData]="tableData" 
      [tableColumns]="tableColumns" 
      [listActions]="actions"
      (edit)="onEdit($event)" 
      (view)="onView($event)"
      (delete)="onDelete($event)"  
      (toList)="onToList($event)" 
      (sortChange)="onSortChange($event)">
    </app-data-table>
  </div>
  <app-pagging [page]="searchData.Page"
    [pageSize]="searchData.PageSize"
    [total]="totalItem"
    (pageSizeChange)="onPageSizeChange($event)"
    (pageChange)="onPageChange($event)" ></app-pagging>
</ng-container>
</div>

