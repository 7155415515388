import { Injectable, Inject } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { APP_CONFIG } from '../utility/tokens';
import { IConfig } from '../models/config.interface';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private notificationEvent = new BehaviorSubject<any>(null);
  private printoutCompletedEvent = new BehaviorSubject<any>(null);
  private userRegistrationCompletedEvent = new BehaviorSubject<any>(null);

  notificationEventHandler = this.notificationEvent.asObservable();
  printoutCompletedEventHandler = this.printoutCompletedEvent.asObservable();
  userRegistrationCompletedEventHandler = this.userRegistrationCompletedEvent.asObservable();

  constructor(protected readonly keycloak: KeycloakService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
    this.startConnection();
  }

  private startConnection() {
    void this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        void this.keycloak.getToken().then(token => {
          this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.environment.api.signalr}`, {
              transport: signalR.HttpTransportType.WebSockets,
              accessTokenFactory: function () { return token; }
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

          this.hubConnection.start().then(() => {
            console.log('SignalR connection established');
            this.setupListeners();
          }).catch(err => console.error(err));
        });
      }
    });
  }

  private setupListeners() {
    this.hubConnection.on('Notification', (anyUnreadNotifications) => {
      this.notificationEvent.next(anyUnreadNotifications);
    });

    this.hubConnection.on('PrintoutCompleted', (appId) => {
      this.printoutCompletedEvent.next(appId);
    });

    this.hubConnection.on('UserRegistrationCompleted', () => {
      this.userRegistrationCompletedEvent.next(null);
    });
  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        console.log('SignalR connection stopped');
      }).catch(err => console.error(err));
    }
  }

  resetConnection() {
    this.stopConnection();
    this.startConnection();
  }
}